

export function spaceRepoName(repoName: string): string {
  const parts = repoName.split('/');
  return parts.length > 1 ? `${parts[0]} / ${parts[1]}` : repoName;
}

const emojis = ['😃', '😊', '🤩', '😍', '🤗', '😺', '😸', '🎃', '👍', '✌️', '💪', '🤝', '🙌', '🌟', '✨', '✅', '🎶'];
const messages = [
  'Thanks!',
  'Thank you!',
  'Appreciate it!',
  'Appreciate your help!',
  'Appreciate your feedback!',
  'Thanks for the feedback!',
  'Thanks for helping!',
  'Noted!',
  'Got it!',
  'Cheers!',
  'You are awesome!'
]

function getRandomElement(arr: string[]): string {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function getThankYouMessage(): string {
  return `${getRandomElement(emojis)} ${getRandomElement(messages)}`;
}